/* eslint-disable max-lines */
import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import {
  DO_YOU_WANT_CHANGE_EMAIL,
  EMAIL_INPUT,
  NAME_INPUT,
  PHONE_INPUT,
  SIGNUP_TYPOGRAPHY,
  START_GIFTING,
} from "../../../constants/pageConstants/loginConstants";
import { desktopIndiaConfig } from "../../../constants/mockData/mockData";
import {
  requestToRegister,
  requestToSaveUserDetails,
  requestToSendOtp,
  requestToShowTNC,
  setTncObjectTitle,
} from "../../../store/slices/loginSignupSlice";
import ButtonComponent from "../../atoms/button";
import CustomImage from "../../atoms/customImage";
import Loader from "../../atoms/loader";
import TextField from "../../atoms/textField";
import Typography from "../../atoms/typography";
import CountryListDropDown from "../../molecules/countryListDropDown";
import Drawer from "../../molecules/drawer";
import LoginSignUpFooter from "../../molecules/loginSignUpFooter";
import Modal from "../../molecules/modal";
import SocialLogin from "../../molecules/socialLogin";
import Encrypt from "../../../utils/ghp/encryption";
import { lockBody, unlockBody } from "../../../utils/document";
import { cleverTapCommonEvents, cleverTapCommonWords, validationFor } from "../../../analytics/clevertapConstants";
import useAnalytics from "../../../analytics/useAnalytics";
import { usePathname } from "next/navigation";

/**
 * SignUp component for user registration.
 * Renders a form for user signup, handles user input,
 * validates input, and sends OTP for registration.
 */
export default function SignUp({ isHeader = false }) {
  const dispatch = useDispatch();
  const { trackEvent } = useAnalytics();
  const pathname = usePathname();
  const [userName, setUserName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [userNameError, setUserNameError] = useState(false);
  const [phoneError, setPhoneError] = useState(false);
  const [selectedCountry, setSelectedCountry] = useState(desktopIndiaConfig?.defaultCountry);
  const [showCountryList, setShowCountryList] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [userConfirmation, setUserConfirmation] = useState(false);
  const existingUser = useSelector((state) => state.loginSignUp?.existingUser);
  const tokens = useSelector((state) => state.loginSignUp?.tokens);
  const completePathName = typeof window !== "undefined" && window.location.href ? window.location.href : "";
  const { userDetails, isMobile } = useSelector(
    (state) => ({
      userDetails: state.loginSignUp?.userDetails,
      isMobile: state?.appConfig?.isMobile,
    }),
    shallowEqual,
  );

  useEffect(() => {
    const registrationViewedEvent = {
      complete_url: completePathName,
      clean_url: pathname,
      registrationViewed: "Registration Viewed",
    };
    trackEvent(cleverTapCommonEvents.registrationViewed, registrationViewedEvent, ["clevertap"]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    if (userNameError) {
      const cleverTapData = {
        page_name: cleverTapCommonWords.REGISTRATION,
        pageType: cleverTapCommonWords.REGISTRATION,
        validation_text: NAME_INPUT.errorMessage,
        validation_for: validationFor.NAME,
      };

      trackEvent(cleverTapCommonEvents.validationViewed, cleverTapData, ["clevertap"]);
    }
  }, [userNameError]);

  useEffect(() => {
    if (userDetails?.userName) {
      setUserName(userDetails.userName);
    }
    if (userDetails?.phoneNumber) {
      setPhoneNumber(userDetails.phoneNumber);
    }
    if (userDetails?.selectedCountry) {
      setSelectedCountry(userDetails.selectedCountry);
    }
  }, [userDetails.phoneNumber, userDetails.selectedCountry, userDetails.userName]);

  /**
   * Handle input change in form fields.
   * @param {Object} event - The input change event.
   */
  const onChange = (event) => {
    const inputId = event.target.id;
    if (inputId === "userName") {
      setUserName(event.target.value);
      setUserNameError(false);
    } else if (inputId === "phoneNumber") {
      setPhoneNumber(event.target.value.replace(/[^0-9]/g, "").replace(/^0+/, ""));
      setPhoneError(false);
    }
  };

  /**
   * Handle login form submission.
   * Validates form inputs, saves user details,
   * sends OTP for verification, and handles OTP response.
   * @param {Object} event - The form submission event.
   */
  const onSignUp = async (event) => {
    event.preventDefault();
    setShowLoader(true);
    if (userName === "" && userName.length < 3) {
      setUserNameError(true);
      setShowLoader(false);
    } else if (selectedCountry.countryName === "India" && (phoneNumber === "" || phoneNumber.length !== 10)) {
      setPhoneError(true);
      setShowLoader(false);
    } else {
      dispatch(requestToSaveUserDetails({ ...userDetails, userName: userName, phoneNumber, selectedCountry }));
      const detailEntered = {
        is_name_available: !!userName.trim().length,
        is_phone_available: phoneNumber !== "",
        is_email_avialable: userDetails?.email !== "",
      };
      trackEvent(cleverTapCommonEvents.regisDetEnter, detailEntered, ["clevertap"]);
      const encryptedEmail = await Encrypt(userDetails?.email);
      const encryptedMobileNumber = await Encrypt(phoneNumber);
      const signUpOtpPayload = {
        un: encryptedEmail,
        countrycode: selectedCountry,
        mn: encryptedMobileNumber,
        logintoken: existingUser?.logintoken,
        _fc_a: tokens?._fc_a,
        _fc_b: tokens?._fc_b,
        deviceType: isMobile ? "mobile" : "desktop",
      };
      dispatch(requestToSendOtp(signUpOtpPayload));
    }
  };
  /**
   * Toggles the visibility of the country list.
   * Sets the state to show or hide the country list.
   * @function onToggleCountryList
   */
  const onToggleCountryList = () => {
    setShowCountryList(!showCountryList);
  };

  /**
   * Handles the click event to reset the phone number.
   * Sets the phone number state to an empty string.
   * @function onClick
   */
  const onResetPhoneNumber = () => {
    setPhoneNumber("");
  };

  /**
   * Handles the click event to reset the phone number.
   * Sets the Name state to an empty string.
   * @function onHandleClick
   */
  const onResetName = () => {
    setUserName("");
  };

  /**
   * Handle click on Terms and Conditions link.
   * Dispatches action to show Terms and Conditions modal.
   */
  const onTNC = (objectTitle) => {
    dispatch(requestToShowTNC(true));
    dispatch(setTncObjectTitle(objectTitle));
    lockBody();
  };
  /** */
  const onUserConfirmation = () => {
    setUserConfirmation(true);
  };

  /** */
  const onCloseModal = () => {
    setUserConfirmation(false);
  };

  /** */
  const onResetEmail = () => {
    dispatch(requestToRegister(false));
  };

  /** */
  const RenderConfirmationMessage = () => {
    return (
      <div className="flex flex-col justify-center">
        <div
          className={`mb-32 mt-24 flex w-full justify-center font-600 ${isMobile || isHeader ? "text-16" : "text-20"}`}
        >
          {DO_YOU_WANT_CHANGE_EMAIL}
        </div>
        <div className="flex w-full justify-between gap-16">
          <ButtonComponent size="medium" buttonVariant="customOutlined" label="YES" onClick={onResetEmail} />
          <ButtonComponent size="medium" buttonVariant="filled" label="NO" onClick={onCloseModal} />
        </div>
      </div>
    );
  };

  /**
   * Handles the selection of a country.
   * Sets the selected country, hides the country list, and dispatches actions to update the state.
   * @function onCountryClickHandler
   * @param {Object} item - The selected country object.
   */
  const onCountryClickHandler = (item) => {
    setShowCountryList(false);
    setSelectedCountry(item);
    onToggleCountryList();
    unlockBody();
  };

  return (
    <div className="flex h-full w-full flex-col rounded-t-3xl bg-white-900 md:rounded-none">
      <div
        className={`flex w-full flex-col flex-wrap rounded-t-3xl bg-white-900 px-16 pb-30 pt-62 md:py-24 md:shadow-none ${isHeader ? "md:px-16" : "md:px-[70px]"}`}
      >
        {showLoader && <Loader />}
        {isMobile && (
          <div className="absolute -top-32 left-1/2 flex h-68  w-68 -translate-x-1/2 transform items-center justify-center rounded-full bg-white-900 shadow-smoke-gray md:hidden">
            <CustomImage src="/icons/fnp-gift.svg" alt="FNP" sizes="10vw" width={48} height={24} />
          </div>
        )}
        <div className="relative flex w-full flex-col flex-wrap">
          <Typography variant="p" Tag="p" classes="inter font-600 text-24 text-fnp-500 leading-6 text-center mb-12">
            {SIGNUP_TYPOGRAPHY.mainHeading}
          </Typography>
          <Typography variant="p" Tag="p" classes="inter font-500 text-14 text-grey-500 leading-4 text-center">
            {SIGNUP_TYPOGRAPHY.subHeading}
          </Typography>
        </div>
        <form autoComplete="off" className="relative mt-24 flex w-full flex-col flex-wrap" onSubmit={onSignUp}>
          <div className="mb-16 w-full">
            <TextField
              label={EMAIL_INPUT.placeholder}
              type="email"
              id="userEmail"
              isRequired={true}
              inputValue={userDetails?.email}
              onChange={onChange}
              variant="primary"
              autoFocus={true}
              startAdornment={
                <CustomImage src="/icons/sms.svg" alt="email icons" sizes="10vw" width={24} height={24} />
              }
              endAdornment={
                userDetails?.email?.length > 0 && (
                  <CustomImage
                    src="/icons/cross-circle.svg"
                    alt="cross icons"
                    sizes="10vw"
                    width={24}
                    height={24}
                    onClick={onUserConfirmation}
                  />
                )
              }
            />
          </div>
          <div className="mb-16 w-full">
            <TextField
              label={NAME_INPUT.placeholder}
              type="text"
              id="userName"
              isRequired={true}
              inputValue={userName}
              variant="primary"
              autoFocus={true}
              onChange={onChange}
              isError={userNameError}
              message={NAME_INPUT.errorMessage}
              startAdornment={
                <CustomImage src="/icons/user-edit.svg" alt="user edit" sizes="10vw" width={24} height={24} />
              }
              endAdornment={
                userName.length > 0 && (
                  <CustomImage
                    src="/icons/cross-circle.svg"
                    alt="cross icons"
                    sizes="10vw"
                    width={24}
                    height={24}
                    onClick={onResetName}
                  />
                )
              }
            />
          </div>
          <div className="relative mb-16 w-full">
            <TextField
              label={PHONE_INPUT.placeholder}
              type="tel"
              id="phoneNumber"
              isRequired={true}
              inputValue={phoneNumber}
              onChange={onChange}
              isError={phoneError}
              message={PHONE_INPUT.errorMessage}
              variant="withComponent"
              autoFocus={false}
              hasStartDivider={true}
              startAdornment={
                <ButtonComponent
                  size="textBtn"
                  buttonVariant="countryDropdown"
                  label={`+${selectedCountry.countryCode}`}
                  onClick={onToggleCountryList}
                  icon={selectedCountry.webLogo}
                  subIcon={`/icons/${showCountryList ? "arrow-up" : "arrow-down"}.svg`}
                  classes="border-none border-gray-300"
                />
              }
              endAdornment={
                phoneNumber.length > 0 && (
                  <CustomImage
                    src="/icons/cross-circle.svg"
                    alt="cross icons"
                    sizes="10vw"
                    width={24}
                    height={24}
                    onClick={onResetPhoneNumber}
                  />
                )
              }
            />
            {!isMobile && showCountryList ? (
              <div className="absolute z-10 mt-4 w-full rounded-8 bg-white-900">
                <CountryListDropDown
                  onCountryClick={onCountryClickHandler}
                  selectedCountry={selectedCountry}
                  isShowCountryCode={true}
                />
              </div>
            ) : (
              <Drawer
                position="bottom"
                isOpen={showCountryList}
                onDrawerClose={onToggleCountryList}
                styles="px-16 max-h-[90vh]"
              >
                <CountryListDropDown
                  onCountryClick={onCountryClickHandler}
                  selectedCountry={selectedCountry}
                  isShowCountryCode={true}
                  title="All Countries"
                  isSearchEnable={true}
                />
              </Drawer>
            )}
          </div>
          <ButtonComponent
            size="medium"
            buttonVariant="filled"
            label={START_GIFTING}
            onClick={onSignUp}
            buttonTextSize="large"
            classes="hover:bg-opacity-80 ease-in-out duration-300"
          />
        </form>
        <SocialLogin isHeader={isHeader} />
        <LoginSignUpFooter onTncClick={onTNC} />
        {userConfirmation && (
          <Modal
            position={isMobile ? "fixed" : "absolute"}
            parentClass={isMobile || isHeader ? "" : "justify-start pt-120"}
            containerClass={`rounded-xl ${isMobile || isHeader ? "min-w-[250px] max-w-full" : "min-w-[450px]"}`}
          >
            <RenderConfirmationMessage />
          </Modal>
        )}
      </div>
    </div>
  );
}

SignUp.propTypes = {
  isHeader: PropTypes.bool,
  completePathName: PropTypes.string,
  pathname: PropTypes.string,
};
