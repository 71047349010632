import cryptoJSCore from "crypto-js/core";
import AES from "crypto-js/aes";
import Pkcs7 from "crypto-js/pad-pkcs7";
import { getGhpCall } from "../../api/loginApi";

const DECRYPTION_KEY = process.env.NEXT_PUBLIC_DECRYPTION_KEY;
const DECRYPTION_IV = process.env.NEXT_PUBLIC_DECRYPTION_IV;

/**
 * Memoized decryption key and IV
 */
let memoizedDecryptionKey, memoizedDecryptionIv;

/**
 * Memoizes the decryption key and IV to avoid unnecessary parsing
 *
 * @returns {Object} An object containing the decryption key and IV
 */
const getMemoizedDecryptionDetails = () => {
  if (!memoizedDecryptionKey || !memoizedDecryptionIv) {
    memoizedDecryptionKey = cryptoJSCore.enc.Base64.parse(DECRYPTION_KEY);
    memoizedDecryptionIv = cryptoJSCore.enc.Base64.parse(DECRYPTION_IV);
  }
  return { memoizedDecryptionKey, memoizedDecryptionIv };
};

/**
 * Decrypts a string using crypto-js
 *
 * @param {string} text string to be decrypted.
 * @returns {string} decrypted string.
 */
export const decrypt = (text) => {
  const { memoizedDecryptionKey, memoizedDecryptionIv } = getMemoizedDecryptionDetails();
  const decrypted = AES.decrypt(text, memoizedDecryptionKey, {
    mode: cryptoJSCore.mode.CBC,
    padding: Pkcs7,
    iv: memoizedDecryptionIv,
  });
  return decrypted.toString(cryptoJSCore.enc.Utf8);
};

/**
 * Fetches and decrypts the encryption key and IV from the server
 *
 * @returns {Promise<void>}
 */
export const fetchAndSetEncryptionDetails = async () => {
  try {
    if (!window.encryptionKey || !window.encryptionIv) {
      const response = await getGhpCall();

      if (response.responseStatus === "success" && response.status === 200) {
        window.encryptionKey = decrypt(response.data.sk);
        window.encryptionIv = decrypt(response.data.ik);
      }
    }
  } catch (e) {
    // errorLogger(e);
  }
};
