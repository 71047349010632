import { useRouter, useSearchParams } from "next/navigation";
import PropTypes from "prop-types";
import { useCallback, useEffect, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import OtpInput from "react18-input-otp";
import { sendOTP, verifyOTP } from "../../../../api/loginApi";
import { LOGGED_IN_SUCCESSFULLY, OTP_TYPOGRAPHY } from "../../../../constants/pageConstants/loginConstants";
import {
  otpError,
  otpVerifyError,
  requestToFetchSignUp,
  requestToHandleSuccessfullToast,
  requestToOpenLoginDrawer,
  requestToOTP,
} from "../../../../store/slices/loginSignupSlice";
import ButtonComponent from "../../../atoms/button";
import Typography from "../../../atoms/typography";
import Encrypt from "../../../../utils/ghp/encryption";
import { initiateFus } from "../../../../store/slices/common/fus";
import { unlockBody } from "../../../../utils/document";
import { ROUTE_CONSTANTS } from "../../../../constants/route";
import {
  BUY_NOW,
  GO_TO_CART,
  INCORRECT_ENTRY,
  PLEASE_ENTER_OTP,
  PROCEED_TO_PAY,
} from "../../../../constants/common/common";
import { getHash } from "../../../../utils/common";
import { cleverTapCommonEvents, cleverTapCommonWords, validationFor } from "../../../../analytics/clevertapConstants";
import useAnalytics from "../../../../analytics/useAnalytics";
import { setSessionStorage } from "../../../../utils/storage/sessionStorageUtil";
import { SOCIAL_LOGIN_KEY } from "../../../../analytics/gaConstant";
import { onClevertapPublish } from "../../../../api/common";
import CustomImage from "../../../atoms/customImage";

/**
 * OTP component handles the OTP verification process for user login/signup.
 *
 * @param {object} props - The component props.
 * @param {string} props.email - The email address to which the OTP is sent.
 * @param {function} props.isShowLoader - This is the function to Show and Hide Loader 
  
 }}
 * @returns {JSX.Element} The OTP component.
 */
export default function OTP({ email, isShowLoader, isHeader }) {
  const router = useRouter();
  const searchParams = useSearchParams();
  const { trackEvent, onUserLogin } = useAnalytics();
  const dispatch = useDispatch();
  const [otp, setOtp] = useState("");
  const [counter, setCounter] = useState(30);
  const [resendEnabled, setResendEnabled] = useState(false);
  const [isOtpError, setIsOtpError] = useState(false);

  const { userDetails, isMobile, tokens, existingUser, isBuyNowClicked } = useSelector(
    (state) => ({
      userDetails: state?.loginSignUp?.userDetails,
      isMobile: state?.appConfig?.isMobile,
      tokens: state.loginSignUp?.tokens,
      existingUser: state.loginSignUp?.existingUser,
      isBuyNowClicked: state.pdp?.checkBuyNowOrProceedToPay,
    }),
    shallowEqual,
  );

  /**
   * Generates validation data for CleverTap analytics.
   *
   * @function
   * @param {string} validationText - The text describing the validation message.
   * @returns {Object} An object containing validation data for CleverTap.
   */
  const cleverTapValidationData = (validationText) => {
    return {
      page_name: cleverTapCommonWords.LOGIN,
      pageType: cleverTapCommonWords.LOGIN,
      validation_text: validationText,
      validation_for: validationFor.OTP_VERIFICATION,
    };
  };

  /**
   * Decreases the counter state by 1 every second.
   * Uses a timeout to update the state after 1000 milliseconds.
   *
   * @function
   * @name reduceSeconds
   * @returns {void}
   */
  const reduceSeconds = useCallback(() => {
    setTimeout(() => {
      setCounter(counter - 1);
    }, 1000);
  }, [counter]);

  useEffect(() => {
    if (counter !== 0) {
      reduceSeconds();
    }
    if (counter === 0) {
      setResendEnabled(true);
    }
  }, [resendEnabled, counter, reduceSeconds]);

  /**
   * Handles the OTP input change event.
   *
   * @param {string} value - The new OTP value.
   */
  const onChange = (value) => {
    setOtp(value);
    setIsOtpError(false);
  };

  /**
   * Encrypts the provided data if available.
   *
   * @async
   * @param {string} data - The data to be encrypted.
   * @returns {Promise<string|null>} - The encrypted data or null if input is empty.
   */
  const encryptData = async (data) => {
    const trimmedData = data?.trim();
    return trimmedData ? await Encrypt(trimmedData) : null;
  };

  /**
   * Handles OTP errors by displaying error messages and updating the state.
   *
   * @param {string} errorMessage - The error message to display.
   */
  const handleOtpError = (errorMessage) => {
    setIsOtpError(true);
    dispatch(otpVerifyError(errorMessage));
    isShowLoader(false);
  };
  /**
   * Redirects the user based on the URL `from` parameter or defaults to the home page.
   */
  const handleRedirection = () => {
    if (isMobile || !isHeader) {
      const redirectBackPath = searchParams.get("from");
      const hash = getHash();
      const combinedPath = redirectBackPath ? `${redirectBackPath}${hash || ""}` : "/";
      const decodedFromUrl = decodeURIComponent(combinedPath);
      router.push(decodedFromUrl);
    } else {
      if (isBuyNowClicked === BUY_NOW || isBuyNowClicked === PROCEED_TO_PAY || isBuyNowClicked === GO_TO_CART) {
        router.push(`/${ROUTE_CONSTANTS.CHECKOUT}/${ROUTE_CONSTANTS.DELIVERY_ADDRESS}`);
      }
      isShowLoader(false);
    }
    const publishCleverTapData = {
      profile: { identity: email },
    };
    onClevertapPublish(publishCleverTapData);
    onUserLogin({
      Site: {
        Identity: email,
        Email: email,
      },
    });
    trackEvent(cleverTapCommonEvents.loginSuccess, { loginSuccess: "Login Success" }, ["clevertap"]);
  };

  /**
   * Handles successful OTP verification, updating user state and redirecting.
   *
   * @param {string} encryptedEmail - The encrypted email of the user.
   */
  const handleOtpSuccess = (encryptedEmail) => {
    unlockBody();
    setSessionStorage(SOCIAL_LOGIN_KEY, true);
    if (userDetails?.isExisting) {
      dispatch(initiateFus());
      dispatch(requestToHandleSuccessfullToast(LOGGED_IN_SUCCESSFULLY));
      dispatch(requestToOpenLoginDrawer(false));
      handleRedirection();
    } else {
      dispatch(
        requestToFetchSignUp({
          loginToken: userDetails.loginToken,
          email: encryptedEmail,
          userName: userDetails.userName,
          countryCode: userDetails.selectedCountry.countryCode,
          phoneNumber: userDetails.phoneNumber,
          handleRedirection,
        }),
      );
    }

    trackEvent(cleverTapCommonEvents.otpVerfDone, { otpVerfDone: "OTP Verification Done" }, ["clevertap"]);
  };

  /**
   * Handles the OTP form submission, including verification and redirection.
   *
   * @async
   * @param {Event} event - The form submission event.
   * @returns {Promise<void>}
   * @description
   * This function encrypts the user's email and phone number, verifies the OTP,
   * and redirects the user based on the verification outcome. It also handles
   * cases where the OTP is empty or verification fails, displaying appropriate
   * error messages and ensuring the loading state is managed correctly.
   */
  const onSubmit = async (event, autoFillOTP) => {
    const quickCode = autoFillOTP || otp;
    event?.preventDefault();
    isShowLoader(true);

    try {
      // Validate OTP input
      if (quickCode?.trim().length === 0) {
        handleOtpError(PLEASE_ENTER_OTP);
        trackEvent(cleverTapCommonEvents.validationViewed, cleverTapValidationData(PLEASE_ENTER_OTP), ["clevertap"]);

        return;
      }

      // Prepare encrypted data
      const encryptedEmail = await encryptData(email);
      const encryptedNumber = await encryptData(userDetails?.phoneNumber);

      // Determine payload based on user type (existing/new)
      const isExistingUser = userDetails?.isExisting;
      const response = await verifyOTP(
        quickCode,
        isExistingUser ? encryptedEmail : null,
        isExistingUser ? null : encryptedNumber,
      );

      // Handle response from OTP verification
      if (response?.response === "error") {
        handleOtpError(response.response.msg);
        trackEvent(cleverTapCommonEvents.validationViewed, cleverTapValidationData(INCORRECT_ENTRY), ["clevertap"]);
        return;
      }

      if (response?.response === "success") {
        handleOtpSuccess(encryptedEmail);
      }
    } catch (error) {
      console.error("OTP submission error:", error);
      isShowLoader(false);
    }
  };

  useEffect(() => {
    if ("OTPCredential" in window) {
      const ac = new AbortController();

      navigator.credentials
        .get({
          otp: { transport: ["sms"] },
          signal: ac.signal,
        })
        .then((newOtp) => {
          if (newOtp?.code) {
            setOtp(newOtp?.code);
            onSubmit(null, newOtp?.code);
          }
        })
        .catch((err) => {
          console.error(`Error while fetching web OTP ${err}`);

          ac.abort();
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * Handles the process of resending the OTP (One-Time Password).
   * Sends an OTP request and updates the state based on the response.
   * @async
   * @function onReSendOTP
   */
  const onReSendOTP = async () => {
    const encryptedEmail = await Encrypt(userDetails?.email);
    const encryptedMobileNumber = await Encrypt(userDetails?.phoneNumber);

    const resendOtpPayload = {
      un: encryptedEmail,
      logintoken: existingUser?.logintoken,
      _fc_a: tokens?._fc_a,
      _fc_b: tokens?._fc_b,
      ...(userDetails?.isExisting
        ? { deviceType: isMobile ? "mobile" : "desktop" } // Add only for existing users.
        : {
            countrycode: userDetails?.selectedCountry?.countryCode,
            mn: encryptedMobileNumber,
          }),
    };

    // Send OTP request based on the payload.
    const otpResponse = await sendOTP(resendOtpPayload);

    if (otpResponse.response === "success") {
      dispatch(requestToOTP(otpResponse));
      setResendEnabled(false);
      setCounter(30);
      setOtp("");
      setIsOtpError(false);
      trackEvent(cleverTapCommonEvents.resendOtpSelected, { resendOtpSelected: "Resend OTP Selected" }, ["clevertap"]);
    } else {
      dispatch(otpError(otpResponse));
    }
  };

  return (
    <form autoComplete="off" onSubmit={onSubmit}>
      <div className="mb-40 mt-[60px] flex w-full flex-wrap items-center justify-between">
        <div className="mb-8 flex w-full">
          <OtpInput
            isInputNum={true}
            value={otp}
            shouldAutoFocus={true}
            onChange={onChange}
            inputStyle={`h-64 w-64 rounded-[14px] border-[1.7px] ${isOtpError ? "border-error-900 " : "border-grey-300"} text-24 font-600 text-black-900`}
            containerStyle="justify-between w-full"
            numInputs={4}
            separator={<span />}
            focusStyle={{ outline: 0 }}
            autoComplete="one-time-code"
          />
        </div>
        {isOtpError && (
          <span
            data-testid="input_related_message"
            className="mb-12 flex w-full items-center rounded-4 bg-red-100 bg-opacity-5 p-4 text-xs font-500 tracking-wide text-grey-500"
          >
            <div>
              <CustomImage src="/icons/warning.svg" width={16} height={16} alt="" sizes="10vw" isPriority={false} />
            </div>
            <p className="ml-4">{`${otp.length === 0 ? PLEASE_ENTER_OTP : INCORRECT_ENTRY}`}</p>
          </span>
        )}
        <Typography variant="p" Tag="p" classes="inter flex flex-none font-400 text-12 text-grey-500">
          {OTP_TYPOGRAPHY.validFor}
        </Typography>
        <Typography variant="p" Tag="div" classes="inter flex flex-none font-400 text-14 text-fnp-500">
          {resendEnabled ? (
            <ButtonComponent
              size="textBtn"
              label={OTP_TYPOGRAPHY.resendOtp}
              buttonVariant="underlineText"
              classes="justify-center"
              onClick={onReSendOTP}
            />
          ) : (
            <span>
              {OTP_TYPOGRAPHY.resendOtp} in <span className="ml-1 w-24 font-600 text-blue-200">{counter}s</span>
            </span>
          )}
        </Typography>
      </div>
      <div className="fixed bottom-0 left-0 w-full border-t-1 border-lightGrey p-16 sm:static sm:p-0">
        <ButtonComponent
          size="medium"
          buttonTextSize="large"
          buttonVariant="filled"
          label={OTP_TYPOGRAPHY.confirmOTP}
          onClick={onSubmit}
          type="submit"
          classes="hover:bg-opacity-80 ease-in-out duration-300"
        />
      </div>
    </form>
  );
}

OTP.propTypes = {
  email: PropTypes.string.isRequired,
  isShowLoader: PropTypes.func.isRequired,
  isHeader: PropTypes.bool,
};
