import { useEffect, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { desktopIndiaConfig } from "../../../constants/mockData/mockData";
import { changePhoneNumber, requestToSaveUserDetails, requestToSendOtp } from "../../../store/slices/loginSignupSlice";
import ButtonComponent from "../../atoms/button";
import CustomImage from "../../atoms/customImage";
import IconButtonComponent from "../../atoms/iconButton";
import TextField from "../../atoms/textField";
import Typography from "../../atoms/typography";
import CountryListDropDown from "../../molecules/countryListDropDown";
import Drawer from "../../molecules/drawer";
import Encrypt from "../../../utils/ghp/encryption";
import isEmpty from "lodash.isempty";

/**
 * Component for updating the phone number.
 * Handles phone number input, validation, and OTP request.
 *
 * @export
 * @returns {JSX.Element} The PhoneNumber component.
 */
export default function PhoneNumber() {
  const dispatch = useDispatch();
  const [phoneNumber, setPhoneNumber] = useState("");
  const [phoneError, setPhoneError] = useState(false);
  const [selectedCountry, setSelectedCountry] = useState(desktopIndiaConfig?.defaultCountry);
  const [showCountryList, setShowCountryList] = useState(false);
  const existingUser = useSelector((state) => state.loginSignUp?.existingUser);
  const tokens = useSelector((state) => state.loginSignUp?.tokens);
  const { userDetails, isMobile } = useSelector(
    (state) => ({
      userDetails: state.loginSignUp.userDetails,
      isMobile: state?.appConfig?.isMobile,
    }),
    shallowEqual,
  );

  useEffect(() => {
    if (userDetails?.phoneNumber) {
      setPhoneNumber(userDetails.phoneNumber);
    }
    if (userDetails?.selectedCountry) {
      setSelectedCountry(userDetails.selectedCountry);
    }
  }, [userDetails.phoneNumber, userDetails.selectedCountry]);

  /**
   * Hides the OTP verification details.
   * Dispatches an action to update the state, indicating that the user has chosen to hide the phone number verification section.
   *
   * @function onHideVerifyDetails
   */
  const onHideVerifyDetails = () => {
    dispatch(changePhoneNumber(false));
  };
  /**
   * Handle input change in form fields.
   * @param {Object} event - The input change event.
   */
  const onPhoneNumberChange = (event) => {
    setPhoneNumber(event.target.value.replace(/[^0-9]/g, ""));
    setPhoneError(false);
  };

  /**
   * Toggles the visibility of the country list.
   * Sets the state to show or hide the country list.
   * @function onToggleCountryList
   */
  const onToggleCountryList = () => {
    setShowCountryList(!showCountryList);
  };

  /**
   * Handles the click event to reset the phone number.
   * Sets the phone number state to an empty string.
   * @function onClick
   */
  const onResetPhoneNumber = () => {
    setPhoneNumber("");
  };

  /**
   * Handles the phone number update process.
   * If the selected country is India and the phone number is invalid, it sets an error state.
   * Otherwise, it dispatches actions to save user details and request an OTP.
   * @async
   * @function onUpdateNumber
   */
  const onUpdateNumber = async () => {
    if (selectedCountry.countryName === "India" && (phoneNumber === "" || phoneNumber.length !== 10)) {
      setPhoneError(true);
    } else {
      const encryptedEmail = await Encrypt(userDetails?.email);
      const encryptedMobileNumber = await Encrypt(phoneNumber);
      const signUpOtpPayload = {
        un: encryptedEmail,
        countrycode: selectedCountry,
        mn: encryptedMobileNumber,
        logintoken: existingUser?.logintoken,
        _fc_a: tokens?._fc_a,
        _fc_b: tokens?._fc_b,
      };
      dispatch(requestToSendOtp(signUpOtpPayload));
    }
    if (!isEmpty(userDetails)) {
      dispatch(requestToSaveUserDetails({ ...userDetails, phoneNumber }));
    }
  };

  /**
   * Handles the selection of a country.
   * Sets the selected country, hides the country list, and dispatches actions to update the state.
   * @function onCountryClickHandler
   * @param {Object} item - The selected country object.
   */
  const onCountryClickHandler = (item) => {
    setShowCountryList(item);
    setSelectedCountry(item);
    onToggleCountryList();
  };

  return (
    <div className="relative z-10 h-screen w-full sm:h-auto sm:bg-white-900">
      <div className="hidden items-center justify-start py-16 shadow md:flex">
        <IconButtonComponent
          labelColor="w-full font-normal"
          iconSrc="/icons/left-arrow.svg"
          onClick={onHideVerifyDetails}
        />
        <Typography variant="p" Tag="p" classes="inter flex w-9/12 flex-none font-500 text-14 text-fnp-500">
          Verify Details
        </Typography>
      </div>
      <form
        autoComplete="off"
        className="relative flex w-full flex-col flex-wrap p-16 sm:p-9"
        onSubmit={onUpdateNumber}
      >
        <div className="relative mb-16">
          <TextField
            label="Enter Mobile Number"
            type="tel"
            id="phoneNumber"
            isRequired={true}
            inputValue={phoneNumber}
            onChange={onPhoneNumberChange}
            isError={phoneError}
            message="Please enter valid 10 digit mobile no."
            variant="withComponent"
            autoFocus={false}
            hasStartDivider={true}
            startAdornment={
              <ButtonComponent
                size="textBtn"
                buttonVariant="countryDropdown"
                label={`+${selectedCountry.countryCode}`}
                onClick={onToggleCountryList}
                icon={selectedCountry.webLogo}
                subIcon={`/icons/arrow-down.svg`}
                classes="border-none border-gray-300"
              />
            }
            endAdornment={
              phoneNumber.length > 0 && (
                <CustomImage
                  src="/icons/cross-circle.svg"
                  alt="cross icons"
                  sizes="10vw"
                  width={24}
                  height={24}
                  onClick={onResetPhoneNumber}
                />
              )
            }
          />
          {!isMobile && showCountryList ? (
            <div className="absolute z-10 w-full rounded-8 bg-white-900">
              <CountryListDropDown
                onCountryClick={onCountryClickHandler}
                selectedCountry={selectedCountry}
                isShowCountryCode={true}
              />
            </div>
          ) : isMobile ? (
            <Drawer
              position="bottom"
              isOpen={showCountryList}
              onDrawerClose={onToggleCountryList}
              styles="px-16 min-h-[70vh]"
            >
              <CountryListDropDown
                onCountryClick={onCountryClickHandler}
                selectedCountry={selectedCountry}
                isShowCountryCode={true}
                title="All Countries"
                isSearchEnable={true}
              />
            </Drawer>
          ) : (
            <></>
          )}
        </div>
        <div className="fixed bottom-0 left-0 w-full p-16 sm:static sm:p-0">
          <ButtonComponent
            size="medium"
            buttonTextSize="large"
            buttonVariant="filled"
            label="Send OTP for Verification"
            onClick={onUpdateNumber}
            classes="hover:bg-opacity-80 ease-in-out duration-300"
          />
        </div>
      </form>
    </div>
  );
}
