"use client";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { CONTINUE, EMAIL_INPUT, LOGIN_TYPOGRAPHY } from "../../../constants/pageConstants/loginConstants";
import {
  requestToGetCsrfTokens,
  requestToShowTNC,
  requestToValidateExistingUser,
  setTncObjectTitle,
} from "../../../store/slices/loginSignupSlice";
import Regex from "../../../utils/regex";
import ButtonComponent from "../../atoms/button";
import CustomImage from "../../atoms/customImage";
import Loader from "../../atoms/loader";
import TextField from "../../atoms/textField";
import Typography from "../../atoms/typography";
import LoginSignUpFooter from "../../molecules/loginSignUpFooter";
import SocialLogin from "../../molecules/socialLogin";
import Encrypt from "../../../utils/ghp/encryption";
import { lockBody } from "../../../utils/document";
import { cleverTapCommonEvents, cleverTapCommonWords, validationFor } from "../../../analytics/clevertapConstants";
import useAnalytics from "../../../analytics/useAnalytics";
import { SOCIAL_LOGIN_KEY } from "../../../analytics/gaConstant";
import { getSessionStorage, removeSessionStorage } from "../../../utils/storage/sessionStorageUtil";

/**
 * Login Component
 *
 * This component renders the login and sign-up form for FNP.
 * It handles user input for email address, validates the email format,
 * and interacts with the backend API for user authentication.
 *
 * @component
 * @example
 * return (
 *   <Login />
 * )
 */
export default function Login({ isHeader = false }) {
  const dispatch = useDispatch();
  const { trackEvent } = useAnalytics();
  const [userEmail, setUserEmail] = useState("");
  const [emailFormatError, setEmailFormatError] = useState(false);
  const [showLoader, setShowLoader] = useState(false);

  const { userDetails, isMobile } = useSelector(
    (state) => ({
      userDetails: state.loginSignUp,
      isMobile: state?.appConfig?.isMobile,
    }),
    shallowEqual,
  );

  useEffect(() => {
    if (getSessionStorage(SOCIAL_LOGIN_KEY)) removeSessionStorage(SOCIAL_LOGIN_KEY);
  }, []);

  useEffect(() => {
    if (userDetails?.email) {
      setUserEmail(userDetails.email);
    }
  }, [userDetails.email]);

  useEffect(() => {
    if (emailFormatError) {
      const cleverTapData = {
        page_name: cleverTapCommonWords.REGISTRATION,
        pageType: cleverTapCommonWords.REGISTRATION,
        validation_text: EMAIL_INPUT.errorMessage,
        validation_for: validationFor.EMAIL_ID,
      };

      trackEvent(cleverTapCommonEvents.validationViewed, cleverTapData, ["clevertap"]);
    }
  }, [emailFormatError]);

  /**
   * Validates if the given string is a valid email address.
   *
   * @param {string} str - The string to validate.
   * @returns {boolean} - Returns true if the string is a valid email address, otherwise false.
   */
  const isEmailAddress = (str) => {
    return Regex.checkEmail.test(str);
  };

  /**
   * Sets the email format error state based on the given value.
   *
   * @param {string} value - The value to check for email format.
   */
  const setFormatError = (value) => {
    if (value !== "") {
      if (isEmailAddress(value)) {
        setEmailFormatError(false);
      } else {
        setEmailFormatError(true);
      }
    } else {
      setEmailFormatError(true);
    }
  };

  /**
   * Handles the change event for the email input field.
   *
   * @param {React.ChangeEvent<HTMLInputElement>} event - The change event.
   */
  const onChange = (event) => {
    setUserEmail(event.target.value);
    if (userEmail.length > 0) {
      setEmailFormatError(false);
    }
  };

  /**
   * Validates an existing user by encrypting their email and dispatching an action.
   *
   * This function trims the user's email, encrypts it using the `Encrypt` function,
   * and then dispatches an action with the encrypted email if the encryption is successful.
   *
   * @async
   * @function validateExistingUser
   * @returns {Promise<void>} A promise that resolves when the action has been dispatched.
   *
   * @throws {Error} Throws an error if the encryption process fails.

   */
  const validateExistingUser = async () => {
    const trimmedEmail = userEmail.trim();
    const encryptedEmail = await Encrypt(trimmedEmail);
    if (encryptedEmail) {
      dispatch(
        requestToValidateExistingUser({
          email: trimmedEmail,
          un: encryptedEmail,
          deviceType: isMobile ? "mobile" : "desktop",
        }),
      );
    }
  };

  /**
   * Handles the login form submission.
   * Validates the email and interacts with the backend API.
   *
   * @param {React.FormEvent<HTMLFormElement>} event - The form submission event.
   */
  const onLogin = async (event) => {
    event.preventDefault();
    if (userEmail === "" || !isEmailAddress(userEmail)) {
      setFormatError(userEmail);
    } else {
      setShowLoader(true);
      dispatch(requestToGetCsrfTokens());
      validateExistingUser();
      trackEvent(cleverTapCommonEvents.emailEntered, { emailEntered: "Email Entered" }, ["clevertap"]);
    }
  };

  /**
   * Resets the email input field.
   */
  const onResetEmail = () => {
    setUserEmail("");
    setEmailFormatError(false);
  };

  /**
   * Handles the click event to show the Terms and Conditions.
   */
  const onTNC = (objectTitle) => {
    dispatch(requestToShowTNC(true));
    dispatch(setTncObjectTitle(objectTitle));
    lockBody();
  };

  return (
    <div className="relative flex h-full w-full flex-col rounded-t-3xl bg-white-900 md:rounded-none">
      <div
        className={`flex w-full flex-col flex-wrap rounded-t-3xl bg-white-900 px-16 pb-30 pt-62 md:py-24 md:shadow-none ${isHeader ? "md:px-16" : "md:px-[70px]"}`}
      >
        {showLoader && <Loader />}
        {isMobile && (
          <div className="absolute -top-32 left-1/2 flex h-68  w-68 -translate-x-1/2 transform items-center justify-center rounded-full bg-white-900 shadow-smoke-gray md:hidden">
            <CustomImage src="/icons/fnp-gift.svg" alt="FNP" sizes="10vw" width={48} height={24} />
          </div>
        )}
        <div className="relative flex w-full flex-col flex-wrap">
          <Typography variant="p" Tag="p" classes="inter font-600 text-24 text-fnp-500 leading-6 text-center mb-12">
            {LOGIN_TYPOGRAPHY.mainHeading}
          </Typography>
          <Typography variant="p" Tag="p" classes="inter font-400 text-14 text-grey-500 leading-4 text-center">
            {LOGIN_TYPOGRAPHY.subHeading}
          </Typography>
        </div>
        <form autoComplete="off" className="relative mt-24 flex w-full flex-col flex-wrap" onSubmit={onLogin}>
          <div className="mb-16 w-full">
            <TextField
              label={EMAIL_INPUT.placeholder}
              type="email"
              id="userEmail"
              isRequired={true}
              inputValue={userEmail}
              onChange={onChange}
              variant="primary"
              autoFocus={true}
              isError={emailFormatError}
              message={EMAIL_INPUT.errorMessage}
              startAdornment={
                <CustomImage src="/icons/sms.svg" alt="email icons" sizes="10vw" width={24} height={24} />
              }
              endAdornment={
                userEmail.length > 0 && (
                  <CustomImage
                    src="/icons/cross-circle.svg"
                    alt="cross icons"
                    sizes="10vw"
                    width={24}
                    height={24}
                    onClick={onResetEmail}
                  />
                )
              }
            />
          </div>

          <ButtonComponent
            size="medium"
            buttonVariant="filled"
            label={CONTINUE}
            onClick={onLogin}
            buttonTextSize="large"
            classes="hover:bg-opacity-80 ease-in-out duration-300"
          />
        </form>
        <SocialLogin isHeader={isHeader} />
        <LoginSignUpFooter onTncClick={onTNC} />
      </div>
    </div>
  );
}

Login.propTypes = {
  isHeader: PropTypes.bool,
};
