import { useMemo } from "react";
import useOmnitureSetup from "./useOmnitureSetup";
import useCommonOmnitureData from "./useCommonOmnitureData";

/**
 * Login page omniture setup
 */
export default function useLoginOmnitureSetup() {
  const { checkLoginInfo, catalogId, rootGeoId, existingUser, cartItems } = useCommonOmnitureData();

  const omniData = useMemo(
    () => ({
      userDetails: {
        checkLoginInfo,
      },
      validID: {
        loginToken: existingUser?.logintoken,
        exists: existingUser?.exists,
      },
      checkout: {
        cartItems,
      },
      appConfigs: {
        rootGeoId,
        catalogId,
      },
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [checkLoginInfo?.loggedin, cartItems, existingUser?.exists, catalogId, rootGeoId],
  );
  useOmnitureSetup(omniData, [omniData?.userDetails?.checkLoginInfo]);
}
