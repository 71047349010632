import cryptoJSCore from "crypto-js/core";
import AES from "crypto-js/aes";
import Pkcs7 from "crypto-js/pad-pkcs7";
import { fetchAndSetEncryptionDetails } from "./decryption";

/**
 * Encrypts a string using AES encryption from the crypto-js library.
 *
 * This function first ensures that the encryption key and IV are loaded.
 * It then encrypts the provided text using the loaded key and IV.
 *
 * @param {string} text - The string to be encrypted.
 * @returns {Promise<string|null>} A promise that resolves to the encrypted string in Base64 format,
 * or null if encryption details are not available.
 *
 * @throws {Error} Throws an error if the encryption process fails.
 */
const Encrypt = async (text) => {
  try {
    await fetchAndSetEncryptionDetails(); // Ensure encryption details are loaded

    if (window.encryptionKey && window.encryptionIv) {
      const key = cryptoJSCore.enc.Base64.parse(window.encryptionKey);
      const iv = cryptoJSCore.enc.Base64.parse(window.encryptionIv);
      const parsedText = cryptoJSCore.enc.Utf8.parse(text);
      return AES.encrypt(parsedText.toString(), key, {
        mode: cryptoJSCore.mode.CBC,
        padding: Pkcs7,
        iv,
      }).ciphertext.toString(cryptoJSCore.enc.Base64);
    }

    return null;
  } catch (error) {
    console.error("Encryption failed:", error); // Log the error for debugging
    throw new Error("Encryption process failed."); // Throw a generic error
  }
};

export default Encrypt;
