import PropTypes from "prop-types";
import { FOOTER_TYPOGRAPHY } from "../../../constants/pageConstants/loginConstants";
import Typography from "../../atoms/typography";

/**
 * Renders the footer component for the login/signup page.
 * Displays the main heading and a clickable link for Terms and Conditions.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {function} props.onTncClick - The function to be called when the Terms and Conditions link is clicked.
 * @returns {JSX.Element} The rendered footer component.
 */
export default function LoginSignUpFooter({ onTncClick }) {
  return (
    <div className={`relative mt-20 flex w-full flex-col `}>
      <Typography
        variant="p"
        Tag="p"
        classes="inter flex flex-none font-400 text-10 text-grey-400 leading-4 items-center justify-center text-center px-2"
      >
        {FOOTER_TYPOGRAPHY.mainHeading}
      </Typography>
      <div className="relative flex h-full w-full items-center justify-center md:ml-3 md:w-auto">
        <Typography
          variant="p"
          Tag="span"
          onClick={() => onTncClick("Default")}
          classes="inter flex flex-none font-400 text-10 text-fnp-500 leading-4 items-center justify-center text-center cursor-pointer md:justify-start"
        >
          {FOOTER_TYPOGRAPHY.tnc}
        </Typography>
        <Typography
          variant="p"
          Tag="span"
          onClick={() => onTncClick("Privacy")}
          classes="inter flex flex-none font-400 text-10 text-fnp-500 leading-4 items-center justify-center text-center cursor-pointer md:justify-start"
        >
          {`, ${FOOTER_TYPOGRAPHY.privacyPolicy}`}
        </Typography>
        <span className="inter mx-3 flex flex-none items-center justify-center text-center text-10 font-400 leading-4 text-grey-400 md:justify-start">
          {FOOTER_TYPOGRAPHY.ampersand}
        </span>
        <Typography
          variant="p"
          Tag="span"
          onClick={() => onTncClick("Disclaimers, Limitations and Exclusions of Liability")}
          classes="inter flex flex-none font-400 text-10 text-fnp-500 leading-4 items-center justify-center text-center cursor-pointer md:justify-start"
        >
          {FOOTER_TYPOGRAPHY.disclaimer}
        </Typography>
      </div>
    </div>
  );
}
LoginSignUpFooter.propTypes = {
  onTncClick: PropTypes.func,
};
