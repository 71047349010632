"use client";

import PropTypes from "prop-types";
import { OR_LOGIN_WITH } from "../../../constants/pageConstants/loginConstants";
import ButtonComponent from "../../atoms/button";
import { usePathname, useSearchParams } from "next/navigation";
import Typography from "../../atoms/typography";
import { ROUTE_CONSTANTS } from "../../../constants/route";
import { requestToOpenLoginDrawer } from "../../../store/slices/loginSignupSlice";
import { useDispatch, useSelector } from "react-redux";
import { BUY_NOW, GO_TO_CART, PROCEED_TO_PAY } from "../../../constants/common/common";
import { cleverTapCommonEvents } from "../../../analytics/clevertapConstants";
import useAnalytics from "../../../analytics/useAnalytics";
import { SOCIAL_LOGIN_KEY } from "../../../analytics/gaConstant";
import { setSessionStorage } from "../../../utils/storage/sessionStorageUtil";

/**
 * SocialLogin component renders a section for social login options.
 * It includes a divider with text and a button for Google login.
 *
 * @component
 * @param {Object} props - Component properties.
 * @param {boolean} props.isHeader - Determines if the component is used in the header.
 * @returns {JSX.Element} The rendered SocialLogin component.
 */
const SocialLogin = ({ isHeader }) => {
  const dispatch = useDispatch();
  const { trackEvent } = useAnalytics();
  const pathname = usePathname();
  const searchParams = useSearchParams();
  const isBuyNowClicked = useSelector((state) => state.pdp?.checkBuyNowOrProceedToPay);

  // Convert search params to an object
  const paramsObject = Object.fromEntries(searchParams.entries());

  // Convert the object to a query string format
  const queryString = new URLSearchParams(paramsObject).toString();

  /**
   * Returns the appropriate redirect path based on the `isHeader` prop.
   *
   * @returns {string} The constructed redirect path.
   */
  const redirectPath = () => {
    if (isHeader) {
      if (isBuyNowClicked === PROCEED_TO_PAY || isBuyNowClicked === BUY_NOW || isBuyNowClicked === GO_TO_CART) {
        return `/${ROUTE_CONSTANTS.CHECKOUT}/${ROUTE_CONSTANTS.DELIVERY_ADDRESS}`;
      }
      return `${pathname}${queryString ? `?${queryString}` : ""}`;
    } else {
      const redirectBackPath = searchParams.get("from");
      return redirectBackPath || "/";
    }
  };

  /**
   * Handles the redirection process for social login.
   * Redirects the user to the social login page with the constructed done page URL.
   *
   * @function onHandleSocialLoginRedirection
   * @returns {void}
   */
  const onHandleSocialLoginRedirection = () => {
    const donePageUrl = `${window.location.origin}${redirectPath()}`;
    const redirectionUrl = new URLSearchParams({ DONE_PAGE: donePageUrl });
    window.location.href = `${ROUTE_CONSTANTS.SOCIAL_LOGIN}?${redirectionUrl}`;
    dispatch(requestToOpenLoginDrawer(false));
    trackEvent(cleverTapCommonEvents.googleLoginSelected, { googleLoginSelected: "Google Login Selected" }, [
      "clevertap",
    ]);
    if (redirectionUrl) {
      setSessionStorage(SOCIAL_LOGIN_KEY, true);
    }
  };

  return (
    <>
      <div className="relative my-20 flex w-full items-center justify-center gap-8">
        <span className="h-[2px] flex-1 bg-grey-100" />
        <Typography
          variant="p"
          Tag="p"
          classes="inter flex flex-none font-400 text-14 text-grey-400 leading-4 items-center justify-center text-center px-2"
        >
          {OR_LOGIN_WITH}
        </Typography>
        <span className="h-[2px] flex-1 bg-grey-100" />
      </div>
      <div className="w-full">
        <ButtonComponent
          size="medium"
          buttonVariant="iconWithTextOutlined"
          label=""
          classes=""
          icon="/icons/google.svg"
          onClick={onHandleSocialLoginRedirection}
        />
      </div>
    </>
  );
};

SocialLogin.propTypes = {
  isHeader: PropTypes.bool.isRequired,
};

export default SocialLogin;
