import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getTNC } from "../../../api/loginApi";
import { FOOTER_TYPOGRAPHY } from "../../../constants/pageConstants/loginConstants";
import { requestToShowTNC } from "../../../store/slices/loginSignupSlice";
import ButtonComponent from "../../atoms/button";
import IconButtonComponent from "../../atoms/iconButton";
import Typography from "../../atoms/typography";
import ListDropDown from "../listDropDown";

/**
 * This component handles the countryList dropdown.
 * @returns {JSX.Element} The CheckoutTNC component.
 *
 */
export default function CheckoutTNC() {
  const dispatch = useDispatch();
  const currentObjectTitle = useSelector((state) => state.loginSignUp.tncDropdownObjectTitle);
  const [tncData, setTncData] = useState({});
  const [selectedTopic, setSelectedTopic] = useState({});
  const [allTopics, setAllTopics] = useState([]);
  const [isShowDropDown, setIsShowDropDown] = useState(false);

  /**
   * Fetches Terms and Conditions (T&C) data from an API and updates state variables.
   * Sets the fetched T&C data as well as the selected topic to the first item in the response.
   * @async
   * @function fetchTNC
   */
  const fetchTNC = async () => {
    const response = await getTNC();
    const getTopics = await response.map((item) => {
      return item.topic ? item.topic : "";
    });
    setTncData(response);
    const selectPrimaryTopic = response.find((tncItem) => {
      return tncItem.topic === currentObjectTitle;
    });
    setSelectedTopic(selectPrimaryTopic);
    setAllTopics(getTopics);
  };

  // Set the default selected topic on component mount
  useEffect(() => {
    fetchTNC();
  }, []);
  /**
   * Toggles the dropdown and sets all topics.
   */
  const onShowDropDown = () => {
    setIsShowDropDown(!isShowDropDown);
  };

  /**
   * Handles the selection of an item from the dropdown.
   * @param {string} item - The selected item.
   */
  const onItemSelection = (item) => {
    const selectedItem = tncData.find((tncItem) => {
      return tncItem.topic === item;
    });
    setSelectedTopic(selectedItem);
    setIsShowDropDown(!isShowDropDown);
  };

  /**
   * Merges HTML content from two objects.
   * @param {string} obj1 - The first HTML content.
   * @param {string} obj2 - The second HTML content.
   * @returns {string} The merged HTML content.
   */
  const mergeHtmlContent = (obj1, obj2) => {
    return `${obj1 || ""} ${obj2 || ""}`;
  };

  /**
   * Hides the Terms and Conditions (TNC) by dispatching an action to update the state.
   * @function onHideTNC
   */
  const onHideTNC = () => {
    dispatch(requestToShowTNC(false));
  };

  return (
    <div className="absolute left-0 top-0 z-10 h-screen w-full sm:h-auto sm:bg-white-900 md:h-screen">
      <div className="hidden items-center justify-start py-16 shadow sm:flex">
        <IconButtonComponent
          labelColor="w-full font-normal"
          classes="py-2"
          iconSrc="/icons/left-arrow.svg"
          onClick={onHideTNC}
          altText="left-arrow"
        />
        <Typography variant="p" Tag="p" classes="inter flex w-9/12 flex-none font-600 text-16 text-fnp-500">
          {FOOTER_TYPOGRAPHY.tnc}
        </Typography>
      </div>
      <div className="flex w-full flex-col p-16 sm:p-9">
        <div className="relative flex w-full flex-col justify-start">
          <ButtonComponent
            labelStyle="text-14 font-500"
            size="medium"
            buttonVariant="countryDropdown"
            label={selectedTopic?.topic}
            onClick={onShowDropDown}
            subIcon={`${isShowDropDown ? "/icons/arrow-up.svg" : "/icons/arrow-down.svg"}`}
            classes="border-gray-300"
            subIconClass=""
            subIconStyleWidth={20}
            subIconStyleHeight={20}
          />
          {isShowDropDown && allTopics && (
            <div className="max-h-400 absolute top-56 min-h-80 w-full overflow-auto rounded-lg border bg-white-900 shadow scrollbar-thin scrollbar-webkit">
              <ListDropDown suggestions={allTopics} keyname="cityName" onClick={onItemSelection} />
            </div>
          )}
        </div>

        <div
          className="mt-4 max-h-[calc(100vh-10.313rem)] w-full overflow-auto px-6 py-4 scrollbar-thin scrollbar-webkit"
          id="terms-and-conditions"
          dangerouslySetInnerHTML={{
            __html: mergeHtmlContent(tncData[tncData.length - 1]?.styles, selectedTopic?.html),
          }}
        />
      </div>
    </div>
  );
}
