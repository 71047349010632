import { useEffect, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { OTP_TYPOGRAPHY, VERIFY_DETAILS } from "../../../constants/pageConstants/loginConstants";
import PropTypes from "prop-types";
import { changePhoneNumber, requestToHideOTP, requestToRegister } from "../../../store/slices/loginSignupSlice";
import ButtonComponent from "../../atoms/button";
import CustomImage from "../../atoms/customImage";
import IconButtonComponent from "../../atoms/iconButton";
import Loader from "../../atoms/loader";
import Toast from "../../atoms/toast";
import Typography from "../../atoms/typography";
import OTP from "./ otp";
import { cleverTapCommonEvents } from "../../../analytics/clevertapConstants";
import useAnalytics from "../../../analytics/useAnalytics";

/**
 * VerifyDetails component renders the OTP verification interface.
 * It handles the display of OTP sent message, the user's phone number and email,
 * and provides options to hide the verification details or change the phone number.
 *
 * @returns {JSX.Element} The rendered OTP verification details component.
 */
export default function VerifyDetails({ isHeader = false }) {
  const dispatch = useDispatch();
  const { trackEvent } = useAnalytics();
  const [isToast, setIsToast] = useState(true);
  const [showLoader, setShowLoader] = useState(false);
  const { otpDetails, userDetails } = useSelector(
    (state) => ({
      otpDetails: state.loginSignUp.otpDetails,
      userDetails: state.loginSignUp.userDetails,
    }),
    shallowEqual,
  );

  useEffect(() => {
    trackEvent(cleverTapCommonEvents.otpVerfViewed, { otpVerfViewed: "OTP Verification Viewed" }, ["clevertap"]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  /**
   * Handles the visibility of the toast message.
   *
   * @param {boolean} isHideToast - Determines whether to hide the toast message.
   */
  const onToastChange = (isHideToast) => {
    setIsToast(isHideToast);
  };

  /**
   * Handles the action to hide the OTP verification details.
   * If the user is not an existing user, it dispatches an action to show the registration form.
   *
   */
  const onHideVerifyDetails = () => {
    dispatch(requestToHideOTP(false));
    if (!userDetails.isExisting) {
      dispatch(requestToRegister(true));
    }
  };
  /**
   * Handles the action to change the phone number.
   * Dispatches an action to show the phone number change form.
   *
   */
  const onChangeNumber = () => {
    dispatch(changePhoneNumber(true));
  };

  return (
    <div className="relative z-1 h-full w-full bg-white-900 sm:h-auto">
      {showLoader && <Loader />}
      <div
        className={`md:border-b-grey-100 flex items-center justify-start gap-8 bg-fnp-100 px-16 py-10 shadow-sm md:gap-12 md:border-b-1 md:bg-white-900 md:py-20 md:pe-16 ${isHeader ? "md:ps-16" : "md:ps-[70px]"}`}
      >
        <span className="w-auto">
          <IconButtonComponent
            labelColor="w-full font-normal"
            classes="py-2"
            iconSrc="/icons/left-arrow.svg"
            onClick={onHideVerifyDetails}
            imgWidth={18}
            imgHeight={20}
            imageContainerMargin="flex items-center"
            isPriority={false}
          />
        </span>
        <Typography variant="p" Tag="p" classes="inter font-600 text-16 text-fnp-500">
          {VERIFY_DETAILS}
        </Typography>
      </div>
      <div
        className={`flex w-full flex-col bg-gradient-to-b from-fnp-100 px-28 pb-16 pt-28 md:bg-none ${isHeader ? "md:px-16" : "md:px-[70px]"}`}
      >
        <div className="flex w-full flex-col justify-start">
          <Typography
            variant="p"
            Tag="p"
            classes="inter flex flex-none font-600 text-14 text-fnp-500 leading-4 text-center mb-13"
          >
            {OTP_TYPOGRAPHY.otpSendMsg}
          </Typography>
          <Typography
            variant="p"
            Tag="div"
            classes="inter flex font-400 text-14 text-grey-500 leading-4 text-center mb-8 items-center gap-2"
          >
            <CustomImage src="/icons/mobile-dualtone.svg" alt="cross icons" sizes="10vw" width={18} height={18} />{" "}
            <span>{otpDetails?.phoneNumber}</span>
            {!userDetails?.isExisting && (
              <div className="ml-4">
                <ButtonComponent
                  size="textBtn"
                  label="Change"
                  buttonVariant="underlineText"
                  classes="justify-center"
                  onClick={onChangeNumber}
                />
              </div>
            )}
          </Typography>
          <Typography
            variant="p"
            Tag="p"
            classes="inter flex flex-none font-400 text-14 text-grey-500 leading-4 text-center gap-2"
          >
            <CustomImage src="/icons/sms-dualtone.svg" alt="cross icons" sizes="10vw" width={18} height={18} />
            {otpDetails?.email}
          </Typography>
        </div>
        <OTP email={otpDetails?.email} isShowLoader={setShowLoader} isHeader={isHeader} />

        {isToast && (
          <div className="fixed bottom-20 left-0 mt-16 flex w-full flex-col items-center sm:static">
            <Toast
              variant="success"
              isToast={true}
              position="fixed bottom-100 sm:mb-0 sm:static"
              header="OTP sent to mobile & email ID"
              duration={5000}
              onToastChange={onToastChange}
            />
          </div>
        )}
      </div>
    </div>
  );
}

VerifyDetails.propTypes = {
  isHeader: PropTypes.bool,
};
