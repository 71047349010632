import PropTypes from "prop-types";
import CustomImage from "../../atoms/customImage";
import { shallowEqual, useSelector } from "react-redux";

/**
 * LoginSignUpImage component renders an image in a fixed or absolute position based on screen size.
 * @param {object} props - Component properties.
 * @param {string} props.url - The URL of the image to be displayed.
 * @returns {JSX.Element} The rendered LoginSignUpImage component.
 */
export default function LoginSignUpImage({ url, width = 24, height = 24, isHeader = false }) {
  const { isMobile } = useSelector(
    (state) => ({
      isMobile: state?.appConfig?.isMobile,
    }),
    shallowEqual,
  );
  return (
    <div className={`h-full w-full object-cover ${isHeader ? "h-max" : ""}`}>
      <CustomImage
        src={url}
        alt="email icons"
        sizes="100vw"
        width={width}
        height={height}
        customStyle={{
          width: "100%",
          height: isMobile || isHeader ? "auto" : "100%",
        }}
      />
    </div>
  );
}
LoginSignUpImage.propTypes = {
  url: PropTypes.string.isRequired,
  width: PropTypes.number,
  height: PropTypes.number,
  isHeader: PropTypes.bool,
};
